import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import {Controller} from "react-hook-form";
import {Control} from "react-hook-form/dist/types/form";
import {BLACK_COLOR, LIGHTEST_GREY_COLOR, PRIMARY_TEXT_COLOR, WHITE_COLOR} from "../../consts";
import {RegisterOptions} from "react-hook-form/dist/types/validator";

function SingleSelectController(
    {
        options,
        selectedOption,
        name,
        control,
        valueFunc = (option: any) => option,
        labelFunc = (option: any) => option?.name,
        sortFunc,
        rules,
    }: Readonly<{
        options: any[],
        selectedOption?: any,
        name: string,
        control: Control,
        valueFunc?: (option: any) => any,
        labelFunc?: (option: any) => string,
        sortFunc?: (a: any, b: any) => number,
        rules?: RegisterOptions,
    }>) {

    const preparedOptions = () => {
        let resultOptions = [...options]
        if (sortFunc) {
            resultOptions = resultOptions.sort(sortFunc)
        }
        return resultOptions
            .map((item) => ({
                value: valueFunc(item),
                label: labelFunc(item),
            }))
    }

    return (
        <Controller
            render={(props) => {
                return (
                    <Select
                        className="select"
                        styles={{
                            option: (styles, {isFocused, isSelected}) => {
                                return {
                                    ...styles,
                                    backgroundColor: isFocused ? LIGHTEST_GREY_COLOR : WHITE_COLOR,
                                    color: isSelected ? PRIMARY_TEXT_COLOR : BLACK_COLOR,
                                };
                            },
                        }}
                        options={preparedOptions()}
                        onChange={({value}) => {
                            props.field.onChange(value);
                        }}
                        placeholder="Select"
                        defaultValue={
                            selectedOption
                                ? {
                                    value: valueFunc(selectedOption),
                                    label: labelFunc(selectedOption),
                                }
                                : undefined
                        }
                    />
                );
            }}
            name={name}
            control={control}
            rules={rules}
        />
    );
}

export default SingleSelectController;

