import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {DocumentDetailed} from "../../../../models/documents/Documents";
import {bookItemsDocument, getDocumentByNumber,} from "../../../../services/documents/DocumentsService";
import {DocumentStatus} from "../../../../models/documents/DocumentViews";
import EquipmentTableSelectable, {RequiredFilter} from "../../../../components/equipment/EquipmentTableSelectable";
import {Equipment} from "../../../../models/equipment/Equpment";

function BookItemsLoanDoc() {

    const {docNumber} = useParams();
    const navigate = useNavigate();
    const [bookedItems, setBookedItems] = useState<Equipment[]>([]);
    const [document, setDocument] = useState<DocumentDetailed>();
    const [dateRange, setDateRange] = useState<[Date | undefined, Date | undefined]>([undefined, undefined]);

    useEffect(() => {
        if (docNumber) {
            getDocumentByNumber(docNumber)
                .then((foundDoc: DocumentDetailed) => {
                    if (foundDoc.documentStatusTransfer?.toStatus !== DocumentStatus.CREATED || foundDoc.bookedItems?.length) {
                        alert("The document " + docNumber + " has been already booked\n" +
                            "Update this alert button to prevent any action")
                    }
                    setDocument(foundDoc)
                })
        }
    }, [docNumber]);


    const onBook = async () => {
        const docAsRequest = {...document}
        docAsRequest.bookedItems = bookedItems.map(uiItem => ({
            equipment: uiItem,
            amount: 1
        }))
        // use this for a while
        docAsRequest.approvedStartDate = dateRange[0]
        docAsRequest.approvedFinishDate = dateRange[1]
        await bookItemsDocument(docAsRequest, () => {
            navigate('/documents/' + docNumber)
        })
    }

    return (
        <EquipmentTableSelectable enableGroupSelection={false}
                                  enableEquipmentSelection={true}
                                  onEquipmentChange={setBookedItems}
                                  okButtonTitle="Book"
                                  okButtonAction={onBook}
                                  okButtonDisabled={bookedItems.length === 0}
                                  cancelButtonLink={`/documents/${docNumber}`}
                                  showDateRangeSelector={true}
                                  onDateRangeChange={setDateRange}
                                  requiredFilters={[RequiredFilter.DATA_RANGE]}/>
    );
}

export default BookItemsLoanDoc;

