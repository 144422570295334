import {DocumentStatusTransfer} from "../../../../models/documents/Documents";
import CustomAccordion from "../../../../components/CustomAccordion";
import DocumentCardHistoryTitleAndItemsView from "./DocumentCardHistoryTitleAndItemsView";
import DocumentCardHistoryTabHeader from "./DocumentCardHistoryTabHeader";
import {DocumentStatus} from "../../../../models/documents/DocumentViews";

function DocumentCardHistoryTabCompleted(
    {
        items,
        currentStatusTransfer,
        tabStatusName,
        tabTitle,
        isAccordionOpen
    }:
    Readonly<{
        items: any[],
        currentStatusTransfer: DocumentStatusTransfer,
        tabStatusName: string,
        tabTitle: string,
        isAccordionOpen: boolean
    }>
) {
    const statusTransferInitiator = currentStatusTransfer.initiator
    const accordionContent = () => <DocumentCardHistoryTitleAndItemsView tabTitle={tabTitle} items={items}/>
    const cardHeader = (statusName = tabStatusName) => <DocumentCardHistoryTabHeader
        tabStatusName={statusName}
        statusDate={currentStatusTransfer.operationDate}
        initiatorFio={`${statusTransferInitiator.name} ${statusTransferInitiator.surname}`}/>

    const content = () => {
        if (currentStatusTransfer.toStatus === DocumentStatus.REJECTED) {
            return cardHeader(DocumentStatus.REJECTED)
        } else {
            return <CustomAccordion
                inOpenInitial={isAccordionOpen}
                accordionContent={accordionContent}
                buttonLineContent={cardHeader()}
            />
        }
    }

    return (
        <div className={"document-card_data-step"}>
            {content()}
        </div>
    );
}

export default DocumentCardHistoryTabCompleted;

