function DocumentCardHistoryTitleAndItemsView({
    tabTitle,
    items,
}: Readonly<{
    tabTitle: string;
    items: any[];
}>) {
    const itemsDescription = items?.map((item, index) => (
        <div key={"document-card_data-step_item" + index} className="document-card_data-step_item">
            <div className="document-card_data-step_item-value">
                {item.equipment?.name ?? item?.equipmentGroup?.name}
            </div>
            <div className="document-card_data-step_item-value">
                {item.equipment?.innerId ?? (item?.equipmentGroup?.comment?.trim() || "Any")}
            </div>
            <div className="document-card_data-step_item-value">{item.amount}</div>
        </div>
    ));

    return (
        <div className={"document-card_data-step_content"}>
            <div className={"document-card_data-step_line document-card_data-step_content-text"}>{tabTitle}</div>

            <div className={"document-card_data-step_line document-card_data-step_items"}>{itemsDescription}</div>
        </div>
    );
}

export default DocumentCardHistoryTitleAndItemsView;

