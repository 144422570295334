import {useForm} from "react-hook-form";
import {DocHistTabButton} from "../../../../models/documents/DocumentViews";
import DocumentCardHistoryTabHeader from "./DocumentCardHistoryTabHeader";
import Button from "../../../../components/buttons/Button";

function DocumentCardHistoryTabActiveWithTextArea(
    {
        statusTitle,
        title,
        placeholderTest,
        buttons = [],
        callBackForm
    }: Readonly<{
        statusTitle: string,
        title: string,
        placeholderTest?: string
        buttons: DocHistTabButton[],
        callBackForm?: any
    }>) {

    const {register} = useForm({})

    const handleChange = (event: any) => {
        const {value} = event.target;
        callBackForm(value)
    };

    const buttonsView = buttons.map((button, index) =>
        <Button key={'doc_history_button_' + index}
                btnLabel={button.buttonName}
                btnClassName={button.buttonClasses}
                type="submit"
                children=""
                onClick={button.buttonAction}
        />
    )

    return (
        <div className={"document-card_data-step"}>
            <DocumentCardHistoryTabHeader tabStatusName={statusTitle}/>

            {/*preventDefault to do not toggle any React function related to froms. We use here custom buttons with custom actions
            without it after reloading a current page the React adds reason as query param*/}
            <form onSubmit={(e) => e.preventDefault()}
                  className="document-card_data-step_reason_input form">
                <div className={"document-card_data-step_reason_input_label text-16-bold"}>{title}</div>
                <div className="form__block">
                        <textarea
                            {...register("reason")}
                            className="textarea"
                            placeholder={placeholderTest}
                            onChange={handleChange}
                        />
                </div>
                <div className={"document-card_data-step_content"}>
                    <div className="document-card_data-step_buttons">
                        {buttonsView}
                    </div>
                </div>
            </form>


        </div>
    );
}

export default DocumentCardHistoryTabActiveWithTextArea;

