import {useState} from "react";
import CreateLoanDocSelectEquipmentGroupsStep from "./CreateLoanDocSelectEquipmentGroupsStep";
import CreateLoanDocConfirmStep from "./CreateLoanDocConfirmStep";
import {baseUrl} from "../../../../config";
import {useNavigate} from "react-router-dom";

function CreateLoanDocWizard() {

    const navigate = useNavigate();

    // start the wizard from the first step
    const [currentStep, setCurrentStep] = useState(1);
    const [selectedGroups, setSelectedGroups] = useState([]);

    const onSubmitConfirmStep = async (data: any) => {
        try {
            let res = await fetch(baseUrl + "/document-service/add-document", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
            if (res.status === 200) {
                //todo replace the answer format to a simple id as text in the response without extra wrappers
                const resJson = await res.json();
                // the format from the backend. Should be specified for the certain cases
                const rx = /Created document: (.+)/
                const matches = rx.exec(resJson.data)
                // will be exception if the response formant has changed
                const newDocId = matches[1]
                // go to doc card for the new doc
                navigate('/documents/' + newDocId)
            } else {
                alert("Some error occurred");
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            {(() => {
                if (currentStep === 1) {
                    // step 1 - select Equipment groups
                    return <CreateLoanDocSelectEquipmentGroupsStep onChooseCallback={() => setCurrentStep(2)}
                                                                   onSelectItems={setSelectedGroups}
                                                                   initialSelectedItems={selectedGroups}/>
                } else {
                    // step 2 - provide the other details and confirm
                    return <CreateLoanDocConfirmStep selectedGroups={selectedGroups}
                                                     onFormComplete={onSubmitConfirmStep}
                                                     goToPrevStep={() => setCurrentStep(1)}/>
                }
            })()}
        </>
    );
}

export default CreateLoanDocWizard;

