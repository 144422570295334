import React, {useEffect} from "react";
import Dropdown from "../components/Dropdown";
import EquipmentTable from "../components/equipment/EquipmentTable";
import Search from "../components/Search";
import TabsMenu from "../components/TabsMenu";
import {baseUrl} from "../config";

function EquipmentGlossary() {
    const [searchValue, setSearchValue] = React.useState("");
    const [tabs, setTabs] = React.useState([]);
    const [activeTab, setActiveTab] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);

    const dropdownOptions = [
        {value: "option1", label: "Item", disabled: false, link: "/equipment_glossary/add-item"},
        {value: "option2", label: "Group", disabled: true},
        {value: "option2", label: "Type", disabled: true},
    ];

    useEffect(() => {
        fetch(baseUrl + `/equipment-type-service/get-equipment-type-list`)
            .then((res) => res.json())
            .then((arr) => {
                setTabs(arr.data.sort((a, b) => a.name.localeCompare(b.name)));
                setActiveTab(arr.data[0]);
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        setSearchValue("");
    }, [activeTab]);

    if (isLoading) {
        return <>Загрузка...</>;
    }

    return (
        <>
            {activeTab ? (
                <TabsMenu tabs={tabs} activeTabId={activeTab} setActiveTabId={setActiveTab}/>
            ) : (
                <div className="tabs tabs_empty"></div>
            )}

            <div className="search-and-actions">
                <Search searchValue={searchValue} setSearchValue={setSearchValue} placeholder={"Search by item..."}/>
                <div className="equipment__btn-wrapper">
                    <Dropdown options={dropdownOptions}/>
                </div>
            </div>
            <EquipmentTable
                searchValue={searchValue}
                activeTab={activeTab}
                enableGroupSelection={false}
                enableEquipmentSelection={false}
                onGroupChange={() => {}}
                onEquipmentChange={() => {}}
                goToEquipEditPageOnClick={true}
            />
        </>
    );
}

export default EquipmentGlossary;

