/**
 * Statuses do not much on UI -> backend
 */
export enum DocumentStatus {
    CREATED = 'Created',
    BOOKED = 'Booked',
    GRANTED = 'Granted',
    COMPLETED = 'Completed',
    REJECTED = 'Rejected',
    UNKNOWN = 'Unknown',
}

/**
 * There are different status names on UI and backend
 */
//todo it should be updated to using the same statuses
export const docStatusToDisplayValueMap: Record<string, string> = {
    Created: "Created",
    Booked: "Booked",
    Granted: "Issued",
    Completed: "Completed",
    Rejected: "Rejected",
};

/**
 * as well as doc types
 */
export enum DocumentTypes {
    DONATION = 'Donation',
    LOAN = 'Loan'
}

export interface DocTypeAndStatusMapping {
    statusName: string[]
    title: string[]
}

export const docTypeAndStatusMap = new Map<string, DocTypeAndStatusMapping>([
    [DocumentTypes.DONATION, {
        statusName: [
            DocumentStatus.CREATED,
            DocumentStatus.COMPLETED
        ],
        title: [
            'Equipment for donation',
            'Reason of Donation'
        ]
    }],
    [DocumentTypes.LOAN, {
        statusName: [
            DocumentStatus.CREATED,
            DocumentStatus.BOOKED,
            DocumentStatus.GRANTED,
            DocumentStatus.COMPLETED,
        ],
        title: [
            'Requested equipment',
            'Booked equipment',
            'Equipment for rent',
            'Order report',
        ]
    }]
]);

export interface DocHistTabButton {
    buttonName: string,
    buttonClasses?: string
    buttonAction: (data?: any) => void | Promise<void>,
}

export interface DocumentBaseRequest {
    initiator: {
        id: string
    },
    number: string,
}

export interface CompleteDocWithConclusionRequest extends DocumentBaseRequest {
    conclusion: string
}
