function ButtonText(
    {
        btnLabel,
        onClick,
        btnClassName = "",
        type,
    }: Readonly<{
        btnLabel: string,
        onClick: () => void,
        btnClassName?: string,
        type?: "submit" | "reset" | "button",
    }>
) {
    return (
        <button className={`button ${btnClassName}`} onClick={onClick} type={type}>
            <span className="btn__text">{btnLabel}</span>
        </button>
    );
}
export default ButtonText;

