import {CompleteDocWithConclusionRequest} from "../../models/documents/DocumentViews";
import {baseUrl} from "../../config";
import {DocumentDetailed} from "../../models/documents/Documents";


export const DOCS_BASE_URL_PART = '/document-service'
export const DOCS_DONATION_URL_PART = '/donation-document-service'

export const basePostRequest = async (
    url: string,
    request: any,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void,
): Promise<void> => {
    try {

        const fullUrl = baseUrl + url;
        const res = await fetch(fullUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request)
        });

        // default onSuccess action is reload the current page
        const onSuccess = onSuccessCallback || (() => window.location.reload())
        const onError = onErrorCallback || (() => alert('Error occurred during posting request '
            + request + ' to '
            + fullUrl + ' response status: ' + res.statusText))

        if (res.ok) {
            onSuccess()
        } else {
            onError()
        }

    } catch (err) {
        console.error(err);
    }
};

export const acceptDonationDocument = async (
    request: CompleteDocWithConclusionRequest,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void,
) => {
    await basePostRequest(DOCS_DONATION_URL_PART + "/accept-donation", request, onSuccessCallback, onErrorCallback)
}

// todo the usage of full DocumentDetailed as request will be updated later
export const grantItemsDocument = async (
    request: Partial<DocumentDetailed>,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void,
) => {
    await basePostRequest(DOCS_BASE_URL_PART + "/grant-document", request, onSuccessCallback, onErrorCallback)
}

// todo the usage of full DocumentDetailed as request will be updated later
export const returnItemsDocument = async (
    request: Partial<DocumentDetailed>,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void,
) => {
    await basePostRequest(DOCS_BASE_URL_PART + "/return-document", request, onSuccessCallback, onErrorCallback)
}

export const rejectDocument = async (
    request: DocumentDetailed,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void,
) => {
    await basePostRequest(DOCS_BASE_URL_PART + "/reject-document", request, onSuccessCallback, onErrorCallback)
}

export const bookItemsDocument = async (
    request: Partial<DocumentDetailed>,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void,
) => {
    await basePostRequest(DOCS_BASE_URL_PART + "/book-document", request, onSuccessCallback, onErrorCallback)
}

export const getDocumentByNumber = async (docNumber: string): Promise<DocumentDetailed> => {
    return fetch(baseUrl + `/document-service/get-document?number=${docNumber}`)
        .then((res) => {
            if (!res.ok) {
                throw new Error("Network response was not ok");
            }
            return res.json();
        })
        .then(resultDto => resultDto.data)
        .then((response: DocumentDetailed) => response);
}
