import React from "react";
import {useNavigate} from "react-router-dom";

function EquipmentTableRow({
                               item,
                               enableEquipmentSelection,
                               goToEquipEditPageOnClick = false,
                               selectedEquipment,
                               setSelectedEquipment
                           }) {
    const navigate = useNavigate();
    const isSelected = React.useMemo(() => selectedEquipment.find(i => i.id === item.id), [selectedEquipment, item.id]);

    const handleCardClick = () => {
        if (goToEquipEditPageOnClick) {
            navigate(`/equipment_glossary/${item.id}`);
        }
    };

    const handleCheckboxChange = (event) => {
        const checked = event.target.checked;
        setSelectedEquipment((prevSelected) => checked
            ? [...prevSelected, item]
            : prevSelected.filter((checkedItem) => checkedItem.id !== item.id)
        );
    };

    return (
        <div
            className={`equipment-table-row ${
                enableEquipmentSelection ? "equipment-table-row__check" : "equipment-table-row__link"
            }`}
            onClick={handleCardClick}
        >
            {enableEquipmentSelection && (
                <div className="check">
                    <input
                        type="checkbox"
                        className="custom-checkbox"
                        id={item.name}
                        name={item.name}
                        value={item.name}
                        checked={isSelected || false}
                        onChange={handleCheckboxChange}
                    />
                </div>
            )}
            <div className="equipment-table-row__id">{item.innerId}</div>
            <div className="equipment-table-row__name">{item.name}</div>
            <div className="equipment-table-row__year">{item.year}</div>
            <div className="equipment-table-row__rating">{item.rating}</div>
        </div>
    );
}

export default EquipmentTableRow;

