import {DocumentStatus, DocumentTypes} from "../../models/documents/DocumentViews";
import {DocumentDetailed} from "../../models/documents/Documents";

export const nextDocStatus = (currentStatus: string, docType: string): DocumentStatus => {
    switch (docType) {
        case DocumentTypes.LOAN: {
            switch (currentStatus) {
                case DocumentStatus.CREATED: {
                    return DocumentStatus.BOOKED;
                }
                case DocumentStatus.BOOKED: {
                    return DocumentStatus.GRANTED;
                }
                case DocumentStatus.GRANTED: {
                    return DocumentStatus.COMPLETED;
                }
                default: {
                    return DocumentStatus.UNKNOWN
                }
            }
        }
        case DocumentTypes.DONATION: {
            return currentStatus === DocumentStatus.CREATED
                ? DocumentStatus.COMPLETED
                : DocumentStatus.UNKNOWN
        }
        default: {
            return DocumentStatus.UNKNOWN
        }
    }
}

export const selectItemsList = (transferStatus: string, doc: DocumentDetailed): any[] => {
    switch (doc?.documentType?.name) {
        case DocumentTypes.DONATION: {
            return doc.bookedItems!
        }
        case DocumentTypes.LOAN: {
            switch (transferStatus) {
                case DocumentStatus.CREATED: {
                    return doc.wishedItems!
                }
                case DocumentStatus.BOOKED: {
                    return doc.bookedItems?.length ? doc.bookedItems : doc.wishedItems!
                }
                default: {
                    return doc.bookedItems!
                }
            }
        }
        default: {
            return doc.bookedItems!
        }
    }
}

export const displayDocTypeName = (doc: DocumentDetailed) => {
    switch (doc?.documentType?.name) {
        case DocumentTypes.DONATION:
            return 'Donation'
        case DocumentTypes.LOAN:
            return 'Loan'
        default:
            return 'Document'
    }
};

export const isDocumentFullyCompleted = (doc: DocumentDetailed) => {
    switch (doc?.documentType?.name) {
        case DocumentTypes.DONATION: {
            // donation has only 2 steps
            return doc.documentStatusTransferHistory?.length === 2
        }
        case DocumentTypes.LOAN: {
            // donation has 4 steps or 3 if last is rejected.
            // The rejected is always the last step, so if it's presented, the doc is competed
            return doc.documentStatusTransfer?.toStatus === DocumentStatus.REJECTED
                || doc.documentStatusTransferHistory?.length === 4

        }
    }
}