import enGB from "date-fns/locale/en-GB";
import {useState} from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Calendar} from "akar-icons";

registerLocale("en-GB", enGB);

function SingleDateSelector(
    {
        placeholder,
        setDate,
        dateFormat = "dd-MM-yyyy",
        customClassName = "default"
    }: Readonly<{
        placeholder?: string,
        setDate: any,
        dateFormat?: string,
        customClassName?: string
    }>) {

    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const updateDate = (changedDate: Date | null) => {
        setSelectedDate(changedDate)
        setDate(changedDate)
    }

    return (
        <div className="calendar">
            <DatePicker
                showIcon
                className={customClassName}
                calendarIconClassName={customClassName}
                toggleCalendarOnIconClick
                selected={selectedDate}
                onChange={(date: Date | null) => updateDate(date)}
                dateFormat={dateFormat}
                closeOnScroll={true}
                icon={
                    <Calendar strokeWidth={2}
                              size={16}
                              className={`calendar__icon ${selectedDate ? "calendar__icon--active" : ""}`}/>
                }
                placeholderText={placeholder}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                locale="en-GB"
            />
        </div>
    );
}

export default SingleDateSelector;

