import React from "react";
import EquipmentTableRow from "./EquipmentTableRow";
import {ChevronDownSmall} from "akar-icons";

function EquipmentTableGroup(
    {
        initItems,
        group,
        groupsChange,
        selectedGroups,
        setSelectedGroups,
        enableGroupSelection,
        enableEquipmentSelection,
        selectedEquipment,
        setSelectedEquipment,
        goToEquipEditPageOnClick
    }) {
    // wrapper for initItems to prevent infinite loop when use items as effect deps
    const [items] = React.useState(initItems);

    function toggleCheckbox(event, group) {
        const isChecked = event.target.checked;
        setSelectedGroups((prevSelectedGroups) => {
            if (isChecked) {
                return [...prevSelectedGroups, group];
            } else {
                return prevSelectedGroups.filter((prevGroup) => prevGroup.id !== group.id);
            }
        });
    }

    function toggleGroup(groupId) {
        groupsChange((prev) =>
            prev.map((groupItem) =>
                groupItem.id === groupId
                    ? {
                        ...groupItem,
                        open: !groupItem.open,
                    }
                    : groupItem
            )
        );
    }

    return (
        <div className={`table`}>
            <div className={`${items.length && group.open ? "table-group" : "table-group table-group_close"}`}>
                <div className="check">
                    {enableGroupSelection && (
                        <input
                            type="checkbox"
                            className="custom-checkbox"
                            id={group.name}
                            name={group.name}
                            value={group.name}
                            checked={selectedGroups.find(g => g.id === group.id) || false}
                            onChange={(event) => toggleCheckbox(event, group)}
                        />
                    )}
                    <label htmlFor={group.name}>{group.name}</label>
                </div>
                {items.length !== 0 && (
                    <div
                        className={`chevron ${group.open ? "chevron_open" : "chevron_close"}`}
                        onClick={() => toggleGroup(group.id)}
                    >
                        <ChevronDownSmall strokeWidth={1} size={24} />
                    </div>
                )}
            </div>
            {items.length !== 0 && (
                <div
                    className={`table-group-items ${group.open ? "table-group-items_open" : "table-group-items_close"}`}
                >
                    {items.map((item) => (
                        <EquipmentTableRow
                            item={item}
                            key={item.id}
                            selectedEquipment={selectedEquipment}
                            setSelectedEquipment={setSelectedEquipment}
                            enableEquipmentSelection={enableEquipmentSelection}
                            goToEquipEditPageOnClick={goToEquipEditPageOnClick}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}

export default EquipmentTableGroup;

