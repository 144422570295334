import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as PlusPic } from "../assets/img/Icons/Plus.svg";
import Button from "../components/buttons/Button";
import Search from "../components/Search";
import UsersTable from "../components/UsersTable";

function Users() {
    const [searchValue, setSearchValue] = React.useState("");

    return (
        <>
            <div className="search-and-actions">
                <Search searchValue={searchValue} setSearchValue={setSearchValue} placeholder={"Search by name..."} />
                <Link to="/users/add-user">
                    <Button btnLabel="Add user" btnClassName="btn_default">
                        {<PlusPic />}
                    </Button>
                </Link>
            </div>
            <UsersTable searchValue={searchValue} />
        </>
    );
}
export default Users;

