import {DocumentDetailed,} from "../../../../models/documents/Documents";
import DocumentCardHistoryTabCompleted from "./DocumentCardHistoryTabCompleted";
import {isDocumentFullyCompleted, nextDocStatus, selectItemsList} from "../../../../services/documents/DocumentUtils";
import {docTypeAndStatusMap, DocumentStatus, DocumentTypes} from "../../../../models/documents/DocumentViews";
import DocumentCardHistoryTabUnreached from "./DocumentCardHistoryTabUnreached";
import DocumentDonationCardActiveTabWithText from "../donation/DocumentDonationCardActiveTabWithText";
import DocumentCardHistoryTabCompletedWithTextArea from "./DocumentCardHistoryTabCompletedWithTextArea";
import DocumentLoanCardActiveTab from "../loan/DocumentLoanCardActiveTab";


function DocumentCardHistory(
    {doc,}: Readonly<{ doc: DocumentDetailed, }>
) {

    // completed steps
    const docTransferHistorySize = doc?.documentStatusTransferHistory?.length ?? 0;
    const docTypeName = doc?.documentType?.name
    const typeAndStatusMap = docTypeAndStatusMap.get(docTypeName)!
    const completedTabs = doc?.documentStatusTransferHistory?.map((transferInfo, index) => {
        const title = typeAndStatusMap.title[index] ?? 'Operation is completed'
        const statusName = typeAndStatusMap.statusName[index] ?? DocumentStatus.COMPLETED
        if (statusName === DocumentStatus.COMPLETED) {
            return <DocumentCardHistoryTabCompletedWithTextArea key={'doc_history_complete_tab_' + index}
                                                                statusTitle={statusName}
                                                                statusDate={transferInfo.operationDate}
                                                                initiatorFio={`${transferInfo.initiator.name} ${transferInfo.initiator.surname}`}
                                                                title={title}
                                                                textAreaValue={doc?.conclusion}/>
        } else {
            return <DocumentCardHistoryTabCompleted key={'doc_history_complete_tab_' + index}
                                                    tabTitle={title}
                                                    tabStatusName={statusName}
                                                    items={selectItemsList(transferInfo.toStatus, doc)}
                                                    currentStatusTransfer={transferInfo}
                                                    isAccordionOpen={index === docTransferHistorySize - 1}/>
        }
    })

    // active step
    const isDocCompleted = isDocumentFullyCompleted(doc)
    const activeTab = () => {
        if (!isDocCompleted) {
            switch (docTypeName) {
                case DocumentTypes.DONATION: {
                    return <DocumentDonationCardActiveTabWithText doc={doc}/>
                }
                case DocumentTypes.LOAN: {
                    return <DocumentLoanCardActiveTab doc={doc}/>
                }
            }
        }
    }

    // plated/unreached yet steps
    const lastStepName = doc?.documentStatusTransfer?.toStatus!
    const activeDocStatus = nextDocStatus(lastStepName, docTypeName)
    const activeDocStep = typeAndStatusMap?.statusName.indexOf(activeDocStatus)
    const allDocStepsSize = typeAndStatusMap?.statusName.length
    const planedTabs = []

    if (!isDocCompleted) {
        for (let i = activeDocStep + 1; i < allDocStepsSize; i++) {
            planedTabs.push(
                <DocumentCardHistoryTabUnreached key={"unreached-tab_" + i}
                                                 tabStatusName={typeAndStatusMap?.statusName[i]}/>
            )
        }
    }

    return (
        <>
            {completedTabs}
            {activeTab()}
            {planedTabs}
        </>
    );
}

export default DocumentCardHistory;

