import BackButton from "../../../../components/buttons/BackButton";
import Button from "../../../../components/buttons/Button";
import ButtonText from "../../../../components/buttons/ButtonText";
import {useForm} from "react-hook-form";
import {DocumentDetailed, WishedItem} from "../../../../models/documents/Documents";
import {baseUrl} from "../../../../config";
import {useEffect, useState} from "react";
import SingleDateSelector from "../../../../components/selectors/SingleDateSelector";
import {Minus, Plus} from "akar-icons";
import SingleSelectController from "../../../../components/selectors/SingleSelectController";
import {UserDto} from "../../../../models/users/UserDto";

const defaultLoanDocSupplierId: number = 2

type RuralValidator = {
    consumer?: string
    expeditionName?: string
    askedStartDate?: string
    askedFinishDate?: string
}

const defaultValidationError = 'This field is required'


function CreateLoanDocConfirmStep(
    {
        selectedGroups,
        onFormComplete,
        goToPrevStep,
    }: Readonly<{
        selectedGroups: any[],
        onFormComplete: (data: any) => any
        goToPrevStep: () => void
    }>
) {

    //todo how to update all values to using form?
    // now it used only for dates
    const {
        control,
        register,
        getValues,
    } = useForm<Partial<DocumentDetailed>>({
        defaultValues: {
            documentType: {
                name: "Loan"
            },
            supplier: undefined,
            consumer: undefined,
            wishedItems: [],
            askedStartDate: undefined,
            askedFinishDate: undefined,
            documentStatusTransfer: undefined,
            expeditionName: undefined,
        },
        mode: "onChange",
        reValidateMode: "onBlur",
    });

    const [consumers, setConsumers] = useState<UserDto[]>([]);
    const [supplier, setSupplier] = useState<UserDto>();
    const [askedStartDate, setAskedStartDate] = useState<Date | null>(null);
    const [askedFinishDate, setAskedFinishDate] = useState<Date | null>(null);
    useEffect(() => {
        fetch(baseUrl + `/user-service/get-user-list`)
            .then((res) => res.json())
            .then((arr) => {
                setConsumers(arr.data?.filter((user: UserDto) => user.id !== defaultLoanDocSupplierId))
                setSupplier(arr.data?.find((user: UserDto) => user.id === defaultLoanDocSupplierId))
            });
    }, []);

    let initialWishedItems: WishedItem[] = []

    selectedGroups?.forEach(group =>
        initialWishedItems.push({
            id: group.id,
            equipmentGroup: {
                name: group.name
            },
            amount: 1,
            comment: '',
        })
    );

    const [wishedItems, setWishedItems] = useState<WishedItem[]>(initialWishedItems);

    // it changes on every entered symbol
    //todo how to redo it with reactive forms?
    const setWishedItemComment = (id: number, comment: string) => {
        wishedItems.filter(item => item.id === id)[0].comment = comment
    }

    const updateWishedItemAmount = (id: number, value: number) => {
        setWishedItems(
            wishedItems.map(wi => wi.id === id
                ? {...wi, amount: wi.amount === 0 && value < 0 ? wi.amount : wi.amount + value}
                : wi)
        )
    }

    //todo replace with reactive form errors
    const [myRuralValidator, setMyRuralValidator] = useState<RuralValidator>({});
    const isFormValid = (): boolean => {
        let currentErrors: RuralValidator = {}
        let isValid = true
        const formValues = getValues();
        if (!formValues.consumer) {
            currentErrors.consumer = defaultValidationError
            isValid = false
        }
        if (!formValues.expeditionName?.trim()?.length) {
            currentErrors.expeditionName = defaultValidationError
            isValid = false
        }
        if (!askedStartDate) {
            currentErrors.askedStartDate = defaultValidationError
            isValid = false
        }
        if (!askedFinishDate) {
            currentErrors.askedFinishDate = defaultValidationError
            isValid = false
        }
        if (!askedFinishDate) {
            currentErrors.askedFinishDate = defaultValidationError
            isValid = false
        }
        setMyRuralValidator(currentErrors)
        return isValid
    }

    const onDone = () => {
        if (!isFormValid()) {
            return
        }
        const formValues = getValues();
        formValues.wishedItems = wishedItems
        formValues.documentStatusTransfer = {
            initiator: formValues.consumer
        }
        formValues.askedStartDate = askedStartDate!
        formValues.askedFinishDate = askedFinishDate!
        formValues.supplier = supplier

        onFormComplete(formValues)
    }


    const requiredEquipment = wishedItems.map((item, index) => {
        return <div className="equipment-info" key={'required-equipment' + index}>
            <div className="equipment">
                <div className="equipment-name">{item.equipmentGroup.name}</div>
            </div>
            <div className="counter">
                <button className="sub" onClick={() => updateWishedItemAmount(item.id, -1)}>
                    <Minus strokeWidth={1} size={16}/>
                </button>
                <div className="num">{item.amount}</div>
                <button className="add" onClick={() => updateWishedItemAmount(item.id, 1)}>
                    <Plus strokeWidth={1} size={16}/>
                </button>
            </div>
            <div className="comment-wp">
                <input
                    type="text"
                    placeholder="Optional: Provide details or preferences"
                    className="comment"
                    onChange={(e) => setWishedItemComment(item.id, e.target.value)}
                />
            </div>
        </div>
    })

    return (
        <div className="add-user__wrapper">
            <BackButton onClick={goToPrevStep}/>
            <form onSubmit={(e) => e.preventDefault()} className="add-user__content">
                <h3 className="add-user__title">Create Loan</h3>
                <div className="loan">
                    <div className="loan-equipment-list">
                        <h4 className="title">Required Equipment</h4>
                        <div className="list">
                            {requiredEquipment}
                        </div>
                    </div>
                    <div className="loan-info">
                        <h4 className="title">Information Details</h4>
                        <div className="info-form">
                            <div className="form__block">
                                <label htmlFor="consumer" className="label">
                                    Consumer
                                </label>
                                <SingleSelectController options={consumers}
                                                        name={"consumer"}
                                                        control={control}
                                                        labelFunc={(consumer: UserDto) => consumer.name + ' ' + consumer.surname}/>
                                {myRuralValidator?.consumer &&
                                    <p className="error">{myRuralValidator?.consumer}</p>}
                            </div>
                            <div className="form__block">
                                <label htmlFor="supplier" className="label">
                                    Supplier
                                </label>
                                <input
                                    type="text"
                                    name="supplier"
                                    id="supplier"
                                    className="input full_width"
                                    disabled={true}
                                    value={`${supplier?.name} ${supplier?.surname}`}
                                />
                            </div>
                            <div className="form__block">
                                <label htmlFor="expeditionName" className="label">
                                    Expedition
                                </label>
                                <input
                                    {...register("expeditionName")}
                                    type="text"
                                    name="expeditionName"
                                    id="expeditionName"
                                    className="input full_width"
                                    placeholder="Enter expedition name"
                                />
                                {myRuralValidator?.expeditionName &&
                                    <p className="error">{myRuralValidator?.expeditionName}</p>}
                            </div>
                            <div className="form__block">
                                <label htmlFor="startdate" className="label">
                                    Start date
                                </label>
                                <SingleDateSelector setDate={setAskedStartDate}
                                                    placeholder="Pick the first day of the expedition"
                                                    customClassName="large-form_single-date"/>
                                {myRuralValidator?.askedStartDate &&
                                    <p className="error">{myRuralValidator?.askedStartDate}</p>}
                            </div>
                            <div className="form__block">
                                <label htmlFor="enddate" className="label">
                                    End date
                                </label>
                                <SingleDateSelector setDate={setAskedFinishDate}
                                                    placeholder="Pick the last day of the expedition"
                                                    customClassName="large-form_single-date"/>
                                {myRuralValidator?.askedFinishDate &&
                                    <p className="error">{myRuralValidator?.askedFinishDate}</p>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="btns__container">
                    <div className="btns__container btns__container-toloan btn-container-center">
                        <ButtonText btnLabel="Back" btnClassName="btn-medium" onClick={goToPrevStep}></ButtonText>
                        <Button btnLabel="Done" btnClassName="btn_default btn-medium" onClick={onDone}></Button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default CreateLoanDocConfirmStep;

