import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {DocumentDetailed} from "../../../models/documents/Documents";
import DocumentDonationCardDetails from "./donation/DocumentDonationCardDetails";
import DocumentCardHistory from "./history/DocumentCardHistory";
import DocumentLoanCardDetails from "./loan/DocumentLoanCardDetails";
import {displayDocTypeName} from "../../../services/documents/DocumentUtils";
import {DocumentTypes} from "../../../models/documents/DocumentViews";
import BackButton from "../../../components/buttons/BackButton";
import {getDocumentByNumber} from "../../../services/documents/DocumentsService";


function DocumentCard() {
    const {docNumber} = useParams();
    const navigate = useNavigate();
    const [item, setItem] = useState<DocumentDetailed>();

    useEffect(() => {
        if (docNumber) {
            getDocumentByNumber(docNumber)
                .then((foundDoc: DocumentDetailed) => {
                    setItem(foundDoc)
                })
        }
    }, [docNumber]);

    const goToDocumentList = () => navigate(`/documents`);

    return (
        <>
            <BackButton
                btnLabel={`${displayDocTypeName(item!)} ${item?.number}`}
                onClick={goToDocumentList}/>
            <div className="document-card">
                <div className="document-card_data">
                    <DocumentCardHistory doc={item!}/>
                </div>
                <div className="document-card_details">
                    {(() => {
                        switch (item?.documentType?.name) {
                            case DocumentTypes.DONATION: {
                                return <DocumentDonationCardDetails></DocumentDonationCardDetails>
                            }
                            case DocumentTypes.LOAN: {
                                return <DocumentLoanCardDetails doc={item}></DocumentLoanCardDetails>
                            }
                            default: {
                                return <div>Unknown document type</div>
                            }
                        }
                    })()}
                </div>
            </div>
        </>
    );
}

export default DocumentCard;

