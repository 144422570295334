import DocumentCardHistoryTabActiveWithTextArea from "../history/DocumentCardHistoryTabActiveWithTextArea";
import {DocumentDetailed} from "../../../../models/documents/Documents";
import {useNavigate} from "react-router-dom";
import {acceptDonationDocument} from "../../../../services/documents/DocumentsService";

function DocumentDonationCardActiveTabWithText(
    {doc}: Readonly<{ doc: DocumentDetailed }>
) {

    const navigate = useNavigate()

    let enteredText = ''
    const handleFormChange = (textAreaData: string) => {
        enteredText = textAreaData
    }

    let onSubmit = async () => {
        await acceptDonationDocument(
            {
                initiator: {
                    id: String(doc.documentStatusTransfer?.initiator?.id!)
                },
                number: doc.number,
                conclusion: enteredText
            },
            () => navigate(`/documents/${doc.number}`),
            () => alert('Error occurred'))
    };

    const buttons = [
        {
            buttonName: 'Confirm Donation',
            buttonAction: onSubmit,
            buttonClasses: 'btn_default btn-medium'
        },
        {
            buttonName: 'Edit Selection',
            buttonAction: () => {
            },
            buttonClasses: 'btn_outline btn-medium'
        },
    ]

    return (
        <DocumentCardHistoryTabActiveWithTextArea
            statusTitle="Completed"
            title="Reason of Donation"
            buttons={buttons}
            placeholderTest="Enter the reason for donating the item (e.g. no longer needed, suplus stock)"
            callBackForm={handleFormChange}/>
    );
}

export default DocumentDonationCardActiveTabWithText;

