import axios from "axios";
import React, {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import InputMask from "react-input-mask";
import {useNavigate, useParams} from "react-router-dom";
import BackButton from "../components/buttons/BackButton";
import Button from "../components/buttons/Button";
import ButtonText from "../components/buttons/ButtonText";
import Modal from "../components/Modal";
import {baseUrl} from "../config";
import {USER_LINK_PATTERN} from "../consts";

function UserCard() {
    const {
        register,
        handleSubmit,
        reset,
        getValues,
        formState: { errors, isDirty, isValid },
        control,
    } = useForm({
        defaultValues: {
            department: "",
            faculty: "",
            group: "",
            link: "",
            name: "",
            phone: "",
            role: "",
            status: "",
            surname: "",
        },
        mode: "onChange",
    });

    const [isLoading, setIsLoading] = React.useState(true);
    const [passwordShown, setPasswordShown] = useState(false);
    const [modalActive, setModalActive] = useState(false);
    const [status, setStatus] = useState(null);

    const { id } = useParams();
    const navigate = useNavigate();

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const deleteUser = () => {
        fetch(baseUrl + `/user-service/delete-user?id=${id}`, { method: "DELETE" })
            .then((res) => res.json())
            .then((data) => {
                setModalActive(false);
                navigate("/users");
            })
            .catch((error) => console.error(error));
    };

    const updateUser = (data) => {
        fetch(baseUrl + `/user-service/update-user`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((data) => {
                navigate("/users");
            })
            .catch((error) => console.error(error));
    };

    React.useEffect(() => {
        async function fetchUser() {
            try {
                const { data } = await axios.get(baseUrl + `/user-service/get-user?id=${id}`);

                reset(data.data);
                setStatus(getValues("status"));

                setIsLoading(false);
            } catch (error) {
                alert("Ошибка при получении информации по пользователю!");
                navigate("/users");
            }
        }

        fetchUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <>Загрузка...</>;
    }

    return (
        <div className="user-card__wrapper">
            <BackButton />
            <div className="user-card__content">
                <h3 className="user-card__title">User Card</h3>

                <div className="users-table-row__status user-card__status">
                    <div className={status === "Active" ? "status-bg active" : "status-bg blocked"}>{status}</div>
                </div>

                <form className="user-card__form form" onSubmit={handleSubmit(updateUser)}>
                    <div className="form__block">
                        <label htmlFor="username" className="label">
                            Name
                        </label>
                        <input
                            {...register("name", {
                                required: "This is required",
                                pattern: {
                                    value: /^[A-zА-яЁё]{2,15}$/,
                                    message:
                                        "User name should have from 2 to 15 characters and contains only " +
                                        "latin and/or cyrillic characters",
                                },
                            })}
                            type="text"
                            name="name"
                            id="username"
                            className="input"
                            placeholder="Name"
                        />

                        {errors?.name && <p className="error">{errors?.name?.message || "Error!"}</p>}
                    </div>
                    <div className="form__block">
                        <label htmlFor="surname" className="label">
                            Surname
                        </label>
                        <input
                            {...register("surname", {
                                required: "This is required",
                                pattern: {
                                    value: /^[A-zА-яЁё-]{1,25}$/,
                                    message:
                                        "User surname should have from 1 to 25 characters and contains only " +
                                        "latin, cyrillic and/or [-] characters",
                                },
                            })}
                            type="text"
                            name="surname"
                            id="surname"
                            className="input"
                            placeholder="Surname"
                        />

                        {errors?.surname && <p className="error">{errors?.surname?.message || "Error!"}</p>}
                    </div>
                    <div className="form__block">
                        <label htmlFor="password" className="label">
                            Password
                        </label>
                        <div className="password__container">
                            <input
                                {...register("password", {
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,15}$/,
                                        message:
                                            "User password should have from 6 to 15 characters, at least " +
                                            "one uppercase letter, one lowercase letter, one number and one " +
                                            "special character [@$!%*?&]",
                                    },
                                })}
                                name="password"
                                type={passwordShown ? "text" : "password"}
                                id="password"
                                placeholder="Password"
                                className="input"
                                defaultValue={""}
                                autoComplete="on"
                            />
                            <div
                                className={passwordShown ? "btn-password btn-password_active" : "btn-password"}
                                onClick={togglePasswordVisiblity}
                            >
                                <img src="/Eye.svg" alt="password" />
                            </div>
                        </div>

                        {errors?.password && <p className="error">{errors?.password?.message || "Error!"}</p>}
                    </div>

                    <div className="form__block">
                        <p className="label">Role</p>
                        <div className="radio">
                            <div className="radio_item">
                                <label htmlFor="admin" className="radio__label">
                                    <input
                                        {...register("role")}
                                        name="role"
                                        type="radio"
                                        id="admin"
                                        className="radio__input"
                                        value="Admin"
                                    />
                                    <span>Admin</span>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="radio_item">
                                <label htmlFor="user" className="radio__label">
                                    <input
                                        {...register("role")}
                                        name="role"
                                        type="radio"
                                        id="user"
                                        className="radio__input"
                                        value="User"
                                    />
                                    <span>User</span>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="form__block">
                        <label htmlFor="link" className="label">
                            Link
                        </label>
                        <input
                            {...register("link", {
                                required: "This is required",
                                maxLength: {
                                    value: 255,
                                    message: "user link should have up to 255 characters",
                                },
                                pattern: {
                                    value: USER_LINK_PATTERN,
                                    message: "User link should be a link to facebook, vk, ok, or telegram",
                                },
                            })}
                            name="link"
                            type="text"
                            id="link"
                            className="input"
                            placeholder="Link"
                        />

                        {errors?.link && <p className="error">{errors?.link?.message || "Error!"}</p>}
                    </div>
                    <div className="form__block">
                        <label htmlFor="phone" className="label">
                            Phone
                        </label>

                        <Controller
                            name="phone"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <InputMask
                                    mask="+7-999-999-99-99"
                                    className="input"
                                    name="phone"
                                    id="phone"
                                    maskChar=""
                                    value={field.value}
                                    onChange={field.onChange}
                                >
                                    {(inputProps) => <input {...inputProps} type="text" />}
                                </InputMask>
                            )}
                            rules={{
                                required: "This is required",
                                pattern: {
                                    value: /^\+7-9\d{2}-\d{3}-\d{2}-\d{2}$/,
                                    message: "User phone should match pattern '+7-9##-###-##-##'",
                                },
                            }}
                        />

                        {errors?.phone && <p className="error">{errors?.phone?.message || "Error!"}</p>}
                    </div>
                    <div className="form__block">
                        <label htmlFor="department" className="label">
                            Department
                        </label>
                        <input
                            {...register("department", {
                                required: "This is required",
                                maxLength: {
                                    value: 100,
                                    message: "User department should have up to 100 characters",
                                },
                            })}
                            name="department"
                            type="text"
                            id="department"
                            className="input"
                            placeholder="Department"
                        />

                        {errors?.department && <p className="error">{errors?.department?.message || "Error!"}</p>}
                    </div>
                    <div className="form__block">
                        <label htmlFor="faculty" className="label">
                            Faculty <span className="font-size-small">-</span>{" "}
                            <span className="style-italic">Optional</span>
                        </label>
                        <input
                            {...register("faculty", {
                                required: false,
                                maxLength: {
                                    value: 100,
                                    message: "User faculty should have up to 100 characters",
                                },
                            })}
                            name="faculty"
                            type="text"
                            id="faculty"
                            className="input"
                            placeholder="Faculty"
                        />
                        {errors?.faculty && <p className="error">{errors?.faculty?.message || "Error!"}</p>}
                    </div>
                    <div className="form__block">
                        <label htmlFor="group" className="label">
                            Group <span className="font-size-small">-</span>{" "}
                            <span className="style-italic">Optional</span>
                        </label>
                        <input
                            {...register("group", {
                                required: false,
                                maxLength: {
                                    value: 20,
                                    message: "User group should have up to 20 characters",
                                },
                            })}
                            name="group"
                            type="text"
                            id="group"
                            className="input"
                            placeholder="Group"
                        />
                        {errors?.group && <p className="error">{errors?.group?.message || "Error!"}</p>}
                    </div>

                    <div className="form__block">
                        <p className="label">Status</p>

                        <div className="radio">
                            <div className="radio_item">
                                <label htmlFor="active" className="radio__label">
                                    <input
                                        {...register("status")}
                                        name="status"
                                        type="radio"
                                        id="active"
                                        className="radio__input"
                                        value="Active"
                                    />
                                    <span>Active</span>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="radio_item">
                                <label htmlFor="blocked" className="radio__label">
                                    <input
                                        {...register("status")}
                                        name="status"
                                        type="radio"
                                        id="blocked"
                                        className="radio__input"
                                        value="Blocked"
                                    />
                                    <span>Blocked</span>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="btn-container">
                        <ButtonText onClick={() => setModalActive(true)} btnLabel="Delete user" type="button" />

                        <Button
                            btnLabel="Done"
                            btnClassName="btn_default btn-medium"
                            disabled={!isDirty || !isValid}
                            type="submit"
                        />
                    </div>
                </form>
            </div>
            <Modal active={modalActive} setActive={setModalActive}>
                <h3 className="modal__title">Delete user</h3>
                <p className="modal__text">This action permanently deletes the user</p>
                <div className="btns__container">
                    <Button
                        btnLabel="Cancel"
                        btnClassName="btn_outline btn-medium"
                        onClick={() => setModalActive(false)}
                        type="button"
                    />

                    <Button btnLabel="Delete user" btnClassName="btn_default btn-medium" onClick={deleteUser} />
                </div>
            </Modal>
        </div>
    );
}
export default UserCard;

