function Button(
    {
        btnLabel,
        btnClassName,
        onClick,
        children,
        type
    }: Readonly<{
        btnLabel: string,
        onClick: () => void,
        btnClassName?: string,
        children?: any
        type?: "submit" | "reset" | "button",
    }>) {
    return (
        <button className={`btn ${btnClassName}`} onClick={onClick} type={type}>
            <span className="btn__text">{btnLabel}</span>
            <span className="btn__text">{children}</span>
        </button>
    );
}

export default Button;

//btn-default

