import { FC } from "react";
import { useNavigate } from "react-router-dom";
import {DocumentItem} from "../models/documents/Documents";
import {docStatusToDisplayValueMap} from "../models/documents/DocumentViews";

interface DocumentsTableRowProps {
    item: DocumentItem;
}

const statusTransferClassMap: Record<string, string> = {
    Created: "created",
    Booked: "booked",
    Granted: "issued",
    Completed: "completed",
    Rejected: "rejected",
};

const DocumentsTableRow: FC<DocumentsTableRowProps> = ({ item }) => {
    const navigate = useNavigate();

    const handleDocumentClick = () => {
        navigate(`/documents/${item.number}`);
    };

    const { number, documentType, consumer, supplier, documentStatusTransfer } = item;

    const statusTransferClass = statusTransferClassMap[documentStatusTransfer.toStatus] || "default";

    const formattedDate = documentStatusTransfer.operationDate.slice(0, 10).split("-").reverse().join(".");

    return (
        <div className="documents-table-row documents-table__link" onClick={handleDocumentClick}>
            <div className="documents-table-row__id">{number}</div>
            <div className="documents-table-row__type">{documentType.name}</div>
            <div className="documents-table-row__consumer">{`${consumer.name} ${consumer.surname}`}</div>
            <div className="documents-table-row__supplier">{`${supplier.name} ${supplier.surname}`}</div>
            <div className="documents-table-row__date">{formattedDate}</div>
            <div className="documents-table-row__status">
                <div className={`status-bg ${statusTransferClass}`}>{docStatusToDisplayValueMap[item.documentStatusTransfer.toStatus]}</div>
            </div>
        </div>
    );
};

export default DocumentsTableRow;

