import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    // Strict Mode enables the following development-only behaviors:
    //
    // Your components will re-render an extra time to find bugs caused by impure rendering.
    // Your components will re-run Effects an extra time to find bugs caused by missing Effect cleanup.
    // Your components will re-run refs callbacks an extra time to find bugs caused by missing ref cleanup.
    // Your components will be checked for usage of deprecated APIs.
    //todo is seems React.StrictMode should be used only for develop/debug mode and not on Prod env
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

