import enGB from "date-fns/locale/en-GB";
import React from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../buttons/Button";
import {Calendar} from "akar-icons";

registerLocale("en-GB", enGB);

interface CalendarProps {
    className: string;
    setDateRange: (range: [Date | undefined, Date | undefined]) => void;
    placeholder?:string
}

function DateRangeSelector(
    {
        className,
        setDateRange,
        placeholder = "7/1/2023 - 7/31/2023",
    }: Readonly<CalendarProps>) {

    const [dateRange, setLocalDateRange] = React.useState<[Date | undefined, Date | undefined]>([undefined, undefined]);
    const [startDate, endDate] = dateRange;
    const [isOpen, setIsOpen] = React.useState(false);

    const handleClear = () => {
        setLocalDateRange([undefined, undefined]);
        setDateRange([undefined, undefined]);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <div className="calendar">
            <DatePicker
                showIcon
                toggleCalendarOnIconClick
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update: [Date | null, Date | null]) => {
                    const newRange: [Date | undefined, Date | undefined] = [
                        update[0] ?? undefined,
                        update[1] ?? undefined,
                    ];
                    setLocalDateRange(newRange);
                    setDateRange(newRange);
                }}
                className={startDate ? "react-datepicker__input" : ""}
                isClearable={false}
                open={isOpen}
                onClickOutside={() => setIsOpen(false)}
                closeOnScroll={true}
                onFocus={() => setIsOpen(true)}
                icon={
                    <Calendar strokeWidth={2}
                              size={16}
                              className={`calendar__icon ${startDate ? "calendar__icon--active" : ""}`}/>
                }
                placeholderText={placeholder}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                locale="en-GB"
            >
                {startDate && endDate && (
                    <div className="calendar__buttons-wrapper">
                        <Button
                            btnLabel="Clear"
                            btnClassName="btn_outline btn-small"
                            onClick={handleClear}
                        ></Button>
                        <Button btnLabel="Done" btnClassName="btn_default btn-small" onClick={handleClose}/>
                    </div>
                )}
            </DatePicker>
        </div>
    );
}

export default DateRangeSelector;

