import {DocumentDetailed} from "../../../../models/documents/Documents";
import {grantItemsDocument, rejectDocument} from "../../../../services/documents/DocumentsService";
import DocumentCardHistoryTabActiveDefault from "../history/DocumentCardHistoryTabActiveDefault";
import DocumentCardHistoryTitleAndItemsView from "../history/DocumentCardHistoryTitleAndItemsView";
import {nextDocStatus, selectItemsList} from "../../../../services/documents/DocumentUtils";
import {
    DocHistTabButton,
    docTypeAndStatusMap,
    DocumentStatus,
    DocumentTypes
} from "../../../../models/documents/DocumentViews";
import DocumentLoanCardActiveTabWithText from "./DocumentLoanCardActiveTabWithText";
import {useNavigate} from "react-router-dom";

function DocumentLoanCardActiveTab(
    {doc}: Readonly<{ doc: DocumentDetailed }>
) {
    const navigate = useNavigate();
    const docTypeName = doc?.documentType.name
    const lastCompleteTransStatus = doc?.documentStatusTransfer?.toStatus!
    const activeDocStatus = nextDocStatus(lastCompleteTransStatus, docTypeName)
    const typeAndStatusMap = docTypeAndStatusMap.get(docTypeName)!
    const currentStatusTransferName = typeAndStatusMap?.statusName.indexOf(activeDocStatus)

    const activeTabContent = () => {
        let tabTitle = typeAndStatusMap.title[currentStatusTransferName]
        // if active status BOOKED, but items is not booked yet
        if (tabTitle === docTypeAndStatusMap.get(DocumentTypes.LOAN)?.title[1] && doc.bookedItems?.length === 0) {
            tabTitle = 'Requested equipment'
        }
        return <DocumentCardHistoryTitleAndItemsView
            tabTitle={tabTitle}
            items={selectItemsList(activeDocStatus, doc)}/>
    }

    let onReject = async () => {
        const docAsRequest = {...doc}
        docAsRequest.conclusion = 'TODO not implemented'
        await rejectDocument(docAsRequest)
    };

    let activeTabButtons: DocHistTabButton[] = []
    // if last step is Created, the active will be Book
    if (lastCompleteTransStatus === DocumentStatus.CREATED) {
        activeTabButtons = [
            {
                buttonName: 'Chose items',
                buttonAction: () => navigate('book'),
                buttonClasses: 'btn_default btn-medium'
            },
            {
                buttonName: 'Reject',
                buttonAction: onReject,
                buttonClasses: 'button btn-medium'
            },
        ];
        // if last step is Booked, the active will be Issued
    } else if (lastCompleteTransStatus === DocumentStatus.BOOKED) {
        activeTabButtons = [
            {
                buttonName: 'Issue items',
                buttonAction: () => grantItemsDocument(doc),
                buttonClasses: 'btn_default btn-medium'
            },
            {
                buttonName: 'Reject',
                buttonAction: onReject,
                buttonClasses: 'button btn-medium'
            },
        ];
    }

    const maxStatusTransferIndex = typeAndStatusMap?.statusName.length - 1
    const isLastTransferStep = currentStatusTransferName === maxStatusTransferIndex

    let activeComponent;
    // last step is always for conclusion
    if (isLastTransferStep) {
        activeComponent = <DocumentLoanCardActiveTabWithText doc={doc}/>
    } else {
        activeComponent = <DocumentCardHistoryTabActiveDefault
            statusTitle={activeDocStatus}
            buttons={activeTabButtons}
            tabContent={activeTabContent()}
        />
    }

    return (activeComponent);
}

export default DocumentLoanCardActiveTab;

