import EquipmentTableSelectable from "../../../../components/equipment/EquipmentTableSelectable";

function CreateLoanDocSelectEquipmentGroupsStep(
    {
        initialSelectedItems = [],
        onChooseCallback,
        onSelectItems,
    }: Readonly<{
        initialSelectedItems: any[],
        onChooseCallback: any
        onSelectItems: any
    }>) {

    return (
        <EquipmentTableSelectable enableGroupSelection={true}
                                  enableEquipmentSelection={false}
                                  onGroupChange={onSelectItems}
                                  okButtonTitle="Choose"
                                  okButtonAction={onChooseCallback}
                                  cancelButtonLink="/documents"
                                  showDateRangeSelector={false}
                                  initialSelectedGroups={initialSelectedItems}/>
    );
}

export default CreateLoanDocSelectEquipmentGroupsStep;

