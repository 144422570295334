import React from "react";
import {Controller, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import BackButton from "../components/buttons/BackButton";
import Button from "../components/buttons/Button";
import {baseUrl} from "../config";
import SingleSelectController from "../components/selectors/SingleSelectController";

function AddEquipment() {
    const {
        register,
        formState: { errors, isValid },
        handleSubmit,
        reset,
        control,
    } = useForm({
        defaultValues: {
            rating: "1",
            description: "",
        },
        mode: "onChange",
        reValidateMode: "onBlur",
    });

    const navigate = useNavigate();
    const [groups, setGroups] = React.useState([]);

    let onSubmit = async (data) => {
        try {
            let res = await fetch(baseUrl + "/equipment-service/add-equipment", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            if (res.status === 200) {
                reset();
                navigate("/equipment_glossary");
            } else {
                alert("Some error occured");
            }
        } catch (err) {
            console.log(err);
        }
    };

    React.useEffect(() => {
        fetch(baseUrl + `/equipment-group-service/get-equipment-group-list`)
            .then((res) => res.json())
            .then((arr) => {
                setGroups(arr.data);
            });
    }, []);

    const onError = (error) => {
        console.log("error", error);
    };

    return (
        <div className="add-user__wrapper">
            <BackButton />

            <div className="add-user__content">
                <h3 className="add-user__title">Create equipment item</h3>

                <div className="add-user__info">
                    <form className="add-user__form form" onSubmit={handleSubmit(onSubmit, onError)}>
                        <div className="form_active">
                            <div className="form__block">
                                <label htmlFor="innerId" className="label">
                                    Identifier
                                </label>
                                <input
                                    {...register("innerId", {
                                        required: "This is required",
                                        minLength: {
                                            value: 2,
                                            message: "Inventory number should have from 2 to 20 characters",
                                        },
                                        maxLength: {
                                            value: 20,
                                            message: "Inventory number should have from 2 to 20 characters",
                                        },
                                    })}
                                    type="text"
                                    name="innerId"
                                    id="innerId"
                                    className="input"
                                    placeholder="Identifier"
                                />
                                <div className="error">
                                    {errors?.innerId && <p className="error">{errors?.innerId?.message || "Error!"}</p>}
                                </div>
                            </div>
                            <div className="form__block">
                                <label htmlFor="name" className="label">
                                    Name
                                </label>
                                <input
                                    {...register("name", {
                                        required: "This is required",
                                        minLength: {
                                            value: 2,
                                            message: "Equipment name should have from 2 to 50 characters",
                                        },
                                        maxLength: {
                                            value: 50,
                                            message: "Equipment name should have from 2 to 50 characters",
                                        },
                                    })}
                                    type="text"
                                    name="name"
                                    id="name"
                                    className="input"
                                    placeholder="Name"
                                />
                                <div className="error">
                                    {errors?.name && <p className="error">{errors?.name?.message || "Error!"}</p>}
                                </div>
                            </div>
                            <div className="form__block">
                                <p className="label">Group</p>
                                <SingleSelectController options={groups}
                                                        name={"group"}
                                                        control={control}
                                                        valueFunc={(group) => group.name}
                                                        sortFunc={(a, b) => a.name.localeCompare(b.name)}
                                                        rules={{required: true,}}/>
                                <div className="error">
                                    {errors.group && <p className="error">{errors?.group?.message || "Error!"}</p>}
                                </div>
                            </div>
                            <div className="form__block">
                                <label htmlFor="year" className="label">
                                    Year
                                </label>
                                <input
                                    {...register("year", {
                                        required: "This is required",
                                        min: {
                                            value: 2010,
                                            message: "Equipment year should be in the range [2010-2049]",
                                        },
                                        max: {
                                            value: 2024,
                                            message: "Equipment year should be in the range [2010-2049]",
                                        },
                                        minLength: {
                                            value: 4,
                                            message: "Equipment year should be in the range [2010-2049]",
                                        },
                                        maxLength: {
                                            value: 4,
                                            message: "Equipment year should be in the range [2010-2049]",
                                        },
                                    })}
                                    type="number"
                                    name="year"
                                    id="year"
                                    className="input"
                                    placeholder="Year"
                                />
                                <div className="error">
                                    {errors?.year && <p className="error">{errors?.year?.message || "Error!"}</p>}
                                </div>
                            </div>

                            <div className="form__block">
                                <p className="label">Rating</p>
                                <div className="radio">
                                    <div className="radio_item">
                                        <label htmlFor="rating1" className="radio__label">
                                            <input
                                                {...register("rating")}
                                                name="rating"
                                                type="radio"
                                                id="rating1"
                                                className="radio__input"
                                                value="1"
                                            />{" "}
                                            <span>1</span>
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="radio_item">
                                        <label htmlFor="rating2" className="radio__label">
                                            <input
                                                {...register("rating")}
                                                name="rating"
                                                type="radio"
                                                id="rating2"
                                                className="radio__input"
                                                value="2"
                                            />
                                            <span>2</span>
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="radio_item">
                                        <label htmlFor="rating3" className="radio__label">
                                            <input
                                                {...register("rating")}
                                                name="rating"
                                                type="radio"
                                                id="rating3"
                                                className="radio__input"
                                                value="3"
                                            />
                                            <span>3</span>
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="error">
                                    {errors?.rating && <p className="error">{errors?.rating?.message || "Error!"}</p>}
                                </div>
                            </div>
                            <div className="form__block">
                                <label htmlFor="description" className="label">
                                    Description <span className="font-size-small">-</span>{" "}
                                    <span className="style-italic">Optional</span>
                                </label>
                                <div>
                                    <Controller
                                        name="description"
                                        control={control}
                                        render={({ field }) => {
                                            return (
                                                <textarea
                                                    onChange={field.onChange}
                                                    value={field.value}
                                                    className="textarea"
                                                    id="description"
                                                    maxLength={1000}
                                                />
                                            );
                                        }}
                                    />
                                </div>{" "}
                            </div>
                            <div className="error">
                                {errors.description && (
                                    <p className="error">{errors?.description?.message || "Error!"}</p>
                                )}
                            </div>

                            <div className="btn-container btn-container-center">
                                <Button
                                    btnLabel="Done"
                                    btnClassName="btn_default btn-medium"
                                    type="submit"
                                    disabled={!isValid}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AddEquipment;

