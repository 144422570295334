import {useNavigate} from "react-router-dom";
import {ChevronLeft} from "akar-icons";

function BackButton(
    {
        btnLabel,
        onClick
    }: Readonly<{
        btnLabel?: string,
        onClick?: () => void
    }>
) {
    const navigate = useNavigate();

    const navigateBack = () => {
        if (onClick) {
            onClick()
        } else {
            navigate(-1)
        }
    }

    return (
        <div className="go_back_button">
            <div className="go-back_icon" onClick={() => navigateBack()}><ChevronLeft strokeWidth={1} size={32}/></div>
            <div className="go-back_text">{btnLabel ?? ''}</div>
        </div>
    );
}

export default BackButton;

