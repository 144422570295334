import {baseUrl} from "../../config";
import {Equipment, EquipmentGroup, EquipmentType} from "../../models/equipment/Equpment";

export const EQUIPMENT_BASE_URL_PART = '/equipment-service'
export const EQUIPMENT_TYPE_BASE_URL_PART = '/equipment-type-service'
export const EQUIPMENT_GROUP_BASE_URL_PART = '/equipment-group-service'


export const getEquipmentList = async (
    startDate?: Date,
    finishDate?: Date,
): Promise<Equipment[]> => {
    let queryParams = ''
    if (startDate || finishDate) {
        queryParams += '?'
        if (startDate) {
            queryParams += 'startDate=' + encodeURIComponent(startDate.toISOString())
            if (finishDate) {
                queryParams += '&'
            }
        }
        if (finishDate) {
            queryParams += 'finishDate=' + encodeURIComponent(finishDate.toISOString())
        }
    }
    return fetch(`${baseUrl}${EQUIPMENT_BASE_URL_PART}/get-equipment-list${queryParams}`)
        .then((res) => res.json())
        .then(body => body.data);
}


export const getEquipmentTypeList = async (): Promise<EquipmentType[]> => {
    return fetch(`${baseUrl}${EQUIPMENT_TYPE_BASE_URL_PART}/get-equipment-type-list`)
        .then((res) => res.json())
        .then(body => body.data)
        .then((typeList: EquipmentType[]) => typeList);
}


export const getEquipmentGroupList = async (): Promise<EquipmentGroup[]> => {
    return fetch(`${baseUrl}${EQUIPMENT_GROUP_BASE_URL_PART}/get-equipment-group-list`)
        .then((res) => res.json())
        .then(body => body.data)
        .then((typeList: EquipmentGroup[]) => typeList);
}