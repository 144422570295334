import React, {useEffect, useState} from "react";

import {Link} from "react-router-dom";
import DateRangeSelector from "../selectors/DateRangeSelector";
import TabsMenu from "../TabsMenu";
import Search from "../Search";
import EquipmentTable from "./EquipmentTable";
import ButtonText from "../buttons/ButtonText";
import Button from "../buttons/Button";
import {getEquipmentTypeList} from "../../services/equipment/EquipmentService";
import {EquipmentType} from "../../models/equipment/Equpment";

export enum RequiredFilter {
    DATA_RANGE,
    SEARCH,
}

type RuralValidator = {
    search?: string
    dateRange?: string
}

function EquipmentTableSelectable(
    {
        okButtonTitle = 'ok',
        okButtonAction = () => {
        },
        okButtonDisabled = false,
        cancelButtonTitle = 'Cancel',
        cancelButtonLink = '/documents',
        enableGroupSelection,
        enableEquipmentSelection,
        onGroupChange,
        onEquipmentChange,
        initialSelectedGroups = [],
        showDateRangeSelector = false,
        onDateRangeChange,
        requiredFilters = []
    }: Readonly<{
        okButtonTitle?: string,
        okButtonAction?: () => void,
        okButtonDisabled?: boolean,
        cancelButtonTitle?: string,
        cancelButtonLink?: string,
        enableGroupSelection?: boolean,
        enableEquipmentSelection?: boolean,
        onGroupChange?: any,
        onEquipmentChange?: any,
        initialSelectedGroups?: any[],
        showDateRangeSelector?: boolean,
        onDateRangeChange?: any,
        requiredFilters?: RequiredFilter[]
    }>) {

    const [tabs, setTabs] = useState<EquipmentType[]>([]);
    const [activeTab, setActiveTab] = useState<EquipmentType>();
    const [searchValue, setSearchValue] = useState("");
    const [dateRange, setDateRange] = React.useState<[Date | undefined, Date | undefined]>([undefined, undefined]);
    const [ruralValidator, setRuralValidator] = useState<RuralValidator>({});

    useEffect(() => {
        getEquipmentTypeList()
            .then((list: EquipmentType[]) => {
                //todo move sorting to backend
                setTabs(list.sort((a, b) => a?.name?.localeCompare(b?.name)));
                setActiveTab(list[0]);
            })
    }, []);

    useEffect(() => {
        setSearchValue("");
    }, [activeTab]);

    useEffect(() => {
        if (showDateRangeSelector) {
            if (!getInvalidFilters(false).includes(RequiredFilter.DATA_RANGE)) {
                setRuralValidator({...ruralValidator, dateRange: undefined})
            }
            onDateRangeChange(dateRange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange, showDateRangeSelector]);

    const onOkButtonClick = () => {
        if (getInvalidFilters().length === 0) {
            okButtonAction()
        }
    }

    const getInvalidFilters = (updateValidator = true): RequiredFilter[] => {
        if (requiredFilters?.length > 0) {
            let result: RequiredFilter[] = [];
            if (requiredFilters?.includes(RequiredFilter.DATA_RANGE)) {
                if (!dateRange[0] || !dateRange[1]) {
                    if (updateValidator) {
                        setRuralValidator({...ruralValidator, dateRange: 'The filter must be populated'})
                    }
                    result.push(RequiredFilter.DATA_RANGE)
                }
            }
            if (requiredFilters?.includes(RequiredFilter.SEARCH)) {
                if (!searchValue) {
                    if (updateValidator) {
                        setRuralValidator({...ruralValidator, search: 'The filter must be populated'})
                    }
                    result.push(RequiredFilter.SEARCH)
                }
            }
            return result
        } else {
            return []
        }
    }


    return (
        <>
            {activeTab ? (
                <TabsMenu tabs={tabs} activeTabId={activeTab} setActiveTabId={setActiveTab}/>
            ) : (
                <div className="tabs tabs_empty"></div>
            )}

            <div className="search-and-actions">
                <div className="filter-panel">
                    <div className="form__block">
                        <Search searchValue={searchValue}
                                setSearchValue={setSearchValue}
                                placeholder={"Search by item..."}/>
                        {ruralValidator?.search &&
                            <p className="error">{ruralValidator?.search}</p>}
                    </div>

                    {showDateRangeSelector &&
                        <div className="form__block">
                            <DateRangeSelector className="documents-table_calendar"
                                               setDateRange={setDateRange}
                                               placeholder="Select a date range"/>
                            {ruralValidator?.dateRange &&
                                <p className="error">{ruralValidator?.dateRange}</p>}
                        </div>
                    }
                </div>
            </div>
            <EquipmentTable
                searchValue={searchValue}
                activeTab={activeTab}
                enableGroupSelection={enableGroupSelection}
                enableEquipmentSelection={enableEquipmentSelection}
                onGroupChange={onGroupChange}
                onEquipmentChange={onEquipmentChange}
                dateRange={dateRange}
                initialSelectedGroups={initialSelectedGroups}
            />
            <div className="btns__container btns__container-toloan btn-container-center">
                <Link to={cancelButtonLink}>
                    <ButtonText btnLabel={cancelButtonTitle}
                                btnClassName="btn-medium"/>
                </Link>
                <Button btnLabel={okButtonTitle}
                        btnClassName="btn_default btn-medium"
                        disabled={okButtonDisabled}
                        onClick={onOkButtonClick}/>
            </div>
        </>
    );
}

export default EquipmentTableSelectable;

