import { useEffect, useState } from "react";
import DocumentsTableRow from "./DocumentsTableRow";

import { baseUrl } from "../config";
import { DocumentItem } from "../models/documents/Documents";

interface Option {
    value: string;
    label: string;
}

interface DocumentsTableProps {
    searchValue: string;
    selectedStatus: Option[];
    selectedType: Option[];
    dateRange: [Date | undefined, Date | undefined];
}

function DocumentsTable({ searchValue, selectedStatus, selectedType, dateRange }: DocumentsTableProps) {
    const [arrItems, setArrItems] = useState<DocumentItem[]>([]);

    useEffect(() => {
        fetch(baseUrl + `/document-service/get-document-list`)
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Network response was not ok");
                }
                return res.json();
            })
            .then((data) => {
                setArrItems(data.data);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, []);

    // Функция фильтрации документов
    const filteredItems = arrItems.filter((obj) => {
        // Фильтрация по поисковому значению (поиск по номеру документа или по имени/фамилии потребителя)
        const matchesSearch =
            obj.consumer.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            obj.consumer.surname.toLowerCase().includes(searchValue.toLowerCase()) ||
            obj.number.toLowerCase().includes(searchValue.toLowerCase());

        // Фильтрация по статусу
        const matchesStatus =
            selectedStatus.length === 0 || // Если статусы не выбраны, отображаем все документы
            selectedStatus.some((status) => status.value === obj.documentStatusTransfer.toStatus.toLowerCase());

        // Фильтрация по типу
        const matchesType =
            selectedType.length === 0 || // Если типы не выбраны, отображаем все документы
            selectedType.some((type) => type.value === obj.documentType.name.toLowerCase());

        // Функция для обнуления времени
        const clearTime = (date: Date) => {
            const newDate = new Date(date);
            newDate.setHours(0, 0, 0, 0); // Устанавливаем время на 00:00:00
            return newDate;
        };

        // Фильтрация по дате
        const documentDate = new Date(obj.documentStatusTransfer.operationDate);
        const matchesDateRange =
            (!dateRange[0] || clearTime(documentDate) >= clearTime(dateRange[0])) &&
            (!dateRange[1] || clearTime(documentDate) <= clearTime(dateRange[1]));

        return matchesSearch && matchesStatus && matchesType && matchesDateRange;
    });

    return (
        <>
            <div className="documents-table">
                <div className="documents-table-head documents-table-row">
                    <div className="documents-table-head__item">
                        <p>Document number</p>
                    </div>

                    <div>
                        <p>Document type</p>
                    </div>
                    <div>
                        <p>Consumer</p>
                    </div>

                    <div className="documents-table-head__item">
                        <p>Supplier</p>
                    </div>

                    <div className="documents-table-head__item">
                        <p>Creation date</p>
                    </div>

                    <div className="documents-table-head__item">
                        <p>Status</p>
                    </div>
                </div>

                {filteredItems.map((item) => (
                    <DocumentsTableRow item={item} key={item.number} />
                ))}
            </div>

            {arrItems.length === 0 && (
                <div className="table_empty">
                    <h4 className="title">There’s no documents here - yet</h4>
                    <p className="text">Time to add some documents</p>
                </div>
            )}
        </>
    );
}

export default DocumentsTable;

