import React from "react";
import {Controller, useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import BackButton from "../components/buttons/BackButton";
import Button from "../components/buttons/Button";
import ButtonText from "../components/buttons/ButtonText";
import Modal from "../components/Modal";
import {baseUrl} from "../config";
import SingleSelectController from "../components/selectors/SingleSelectController";

function EquipmentCard() {
    const {
        register,
        formState: { errors, isDirty, isValid },
        handleSubmit,
        reset,
        control,
    } = useForm({
        defaultValues: {
            rating: "1",
            description: "",
            group: "",
        },
        mode: "onChange",
        reValidateMode: "onBlur",
    });

    const navigate = useNavigate();
    const [groups, setGroups] = React.useState([]);
    const [selectedGroup, setSelectedGroup] = React.useState();
    const [editingEquipmentDto, setEditingEquipmentDto] = React.useState();
    const [isLoading, setIsLoading] = React.useState(true);
    const [modalActive, setModalActive] = React.useState(false);

    const { id } = useParams();

    React.useEffect(() => {
        fetch(baseUrl + `/equipment-group-service/get-equipment-group-list`)
            .then((res) => res.json())
            .then((arr) => {
                setGroups(arr.data);
            });
    }, []);

    const deleteItem = () => {
        fetch(baseUrl + `/equipment-service/delete-equipment?id=${id}`, { method: "DELETE" })
            .then((res) => res.json())
            .then((data) => {
                setModalActive(false);
                navigate("/equipment_glossary");
            })
            .catch((error) => console.error(error));
    };

    const updateEquipmentItem = (data) => {
        fetch(baseUrl + `/equipment-service/update-equipment`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((data) => {
                navigate("/equipment_glossary");
            })
            .catch((error) => console.error(error));
    };

    React.useEffect(() => {
        fetch(baseUrl + `/equipment-service/get-equipment?id=${id}`)
            .then(res => res.json())
            .then(jsonRes => jsonRes.data)
            .then(equipmentDto => {
                equipmentDto.rating = String(equipmentDto.rating);
                reset(equipmentDto);
                setEditingEquipmentDto(equipmentDto)
            })
            .catch((error) => console.error(error));
    }, [id, reset])

    React.useEffect(() => {
        if (groups.length > 0 && editingEquipmentDto) {
            setSelectedGroup(groups.find(group => group.name === editingEquipmentDto.group))
            setIsLoading(false);
        }
    }, [groups, editingEquipmentDto])

    if (isLoading) {
        return <>Загрузка...</>;
    }

    return (
        <div className="add-user__wrapper">
            <BackButton />

            <div className="add-user__content">
                <h3 className="add-user__title">Item card</h3>

                <div className="add-user__info">
                    <form className="add-user__form form" onSubmit={handleSubmit(updateEquipmentItem)}>
                        <div className="form_active">
                            <div className="form__block">
                                <label htmlFor="innerId" className="label">
                                    Identifier
                                </label>
                                <input
                                    {...register("innerId", {
                                        required: "This is required",
                                        minLength: {
                                            value: 2,
                                            message: "Inventory number should have from 2 to 20 characters",
                                        },
                                        maxLength: {
                                            value: 20,
                                            message: "Inventory number should have from 2 to 20 characters",
                                        },
                                    })}
                                    type="text"
                                    name="innerId"
                                    id="innerId"
                                    className="input"
                                    placeholder="Identifier"
                                />
                                <div className="error">
                                    {errors?.innerId && <p className="error">{errors?.innerId?.message || "Error!"}</p>}
                                </div>
                            </div>
                            <div className="form__block">
                                <label htmlFor="name" className="label">
                                    Name
                                </label>
                                <input
                                    {...register("name", {
                                        required: "This is required",
                                        minLength: {
                                            value: 2,
                                            message: "Equipment name should have from 2 to 50 characters",
                                        },
                                        maxLength: {
                                            value: 50,
                                            message: "Equipment name should have from 2 to 50 characters",
                                        },
                                    })}
                                    type="text"
                                    name="name"
                                    id="name"
                                    className="input"
                                    placeholder="Name"
                                />
                                <div className="error">
                                    {errors?.name && <p className="error">{errors?.name?.message || "Error!"}</p>}
                                </div>
                            </div>
                            <div className="form__block">
                                <p className="label">Group</p>
                                <SingleSelectController options={groups}
                                                        name={"group"}
                                                        control={control}
                                                        valueFunc={(group) => group.name}
                                                        sortFunc={(a, b) => a.name.localeCompare(b.name)}
                                                        selectedOption={selectedGroup}
                                                        rules={{required: true,}}/>
                                <div className="error">
                                    {errors.group && <p className="error">{errors?.group?.message || "Error!"}</p>}
                                </div>
                            </div>
                            <div className="form__block">
                                <label htmlFor="year" className="label">
                                    Year
                                </label>
                                <input
                                    {...register("year", {
                                        required: "This is required",
                                        min: {
                                            value: 2010,
                                            message: "Equipment year should be in the range [2010-2049]",
                                        },
                                        max: {
                                            value: 2024,
                                            message: "Equipment year should be in the range [2010-2049]",
                                        },
                                        minLength: {
                                            value: 4,
                                            message: "Equipment year should be in the range [2010-2049]",
                                        },
                                        maxLength: {
                                            value: 4,
                                            message: "Equipment year should be in the range [2010-2049]",
                                        },
                                    })}
                                    type="number"
                                    name="year"
                                    id="year"
                                    className="input"
                                    placeholder="Year"
                                />
                                <div className="error">
                                    {errors?.year && <p className="error">{errors?.year?.message || "Error!"}</p>}
                                </div>
                            </div>

                            <div className="form__block">
                                <p className="label">Rating</p>
                                <div className="radio">
                                    <div className="radio_item">
                                        <label htmlFor="rating1" className="radio__label">
                                            <input
                                                {...register("rating")}
                                                name="rating"
                                                type="radio"
                                                id="rating1"
                                                className="radio__input"
                                                value="1"
                                            />{" "}
                                            <span>1</span>
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="radio_item">
                                        <label htmlFor="rating2" className="radio__label">
                                            <input
                                                {...register("rating")}
                                                name="rating"
                                                type="radio"
                                                id="rating2"
                                                className="radio__input"
                                                value="2"
                                            />
                                            <span>2</span>
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="radio_item">
                                        <label htmlFor="rating3" className="radio__label">
                                            <input
                                                {...register("rating")}
                                                name="rating"
                                                type="radio"
                                                id="rating3"
                                                className="radio__input"
                                                value="3"
                                            />
                                            <span>3</span>
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="error">
                                    {errors?.rating && <p className="error">{errors?.rating?.message || "Error!"}</p>}
                                </div>
                            </div>
                            <div className="form__block">
                                <label htmlFor="description" className="label">
                                    Description <span className="font-size-small">-</span>{" "}
                                    <span className="style-italic">Optional</span>
                                </label>
                                <div>
                                    <Controller
                                        name="description"
                                        control={control}
                                        render={({ field }) => {
                                            return (
                                                <textarea
                                                    onChange={field.onChange}
                                                    value={field.value}
                                                    className="textarea"
                                                    id="description"
                                                    maxLength={1000}
                                                />
                                            );
                                        }}
                                    />
                                </div>{" "}
                            </div>
                            <div className="error">
                                {errors.description && (
                                    <p className="error">{errors?.description?.message || "Error!"}</p>
                                )}
                            </div>

                            <div className="btn-container">
                                <ButtonText onClick={() => setModalActive(true)} btnLabel="Delete item" type="button" />

                                <Button
                                    btnLabel="Done"
                                    btnClassName="btn_default btn-medium"
                                    disabled={!isDirty || !isValid}
                                    type="submit"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <Modal active={modalActive} setActive={setModalActive}>
                <h3 className="modal__title">Delete item</h3>
                <p className="modal__text">This action permanently deletes the item</p>
                <div className="btns__container">
                    <Button
                        btnLabel="Cancel"
                        btnClassName="btn_outline btn-medium"
                        onClick={() => setModalActive(false)}
                        type="button"
                    />

                    <Button btnLabel="Delete item" btnClassName="btn_default btn-medium" onClick={deleteItem} />
                </div>
            </Modal>
        </div>
    );
}

export default EquipmentCard;

