import React, {Suspense} from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import AddUser from "./pages/AddUser";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import UserCard from "./pages/UserCard";
import Users from "./pages/Users";

import "./scss/app.scss";

import MainLayout from "./layouts/MainLayout";
import EquipmentGlossary from "./pages/EquipmentGlossary";
import Reports from "./pages/Reports";
import Documents from "./pages/documents/Documents";
import AddEquipment from "./pages/AddEquipment";
import EquipmentCard from "./pages/EquipmentCard";
import DocumentCard from "./pages/documents/card/DocumentCard";
import CreateLoanDocWizard from "./pages/documents/loan/creation/CreateLoanDocWizard";
import BookItemsLoanDoc from "./pages/documents/loan/booking/BookItemsLoanDoc";

function App() {
    React.useEffect(() => {
        document.title = "Equipment manager";
    }, []);

    // Создание маршрутов с использованием createBrowserRouter
    const router = createBrowserRouter(
        [
            {
                path: "/",
                element: <MainLayout/>,
                children: [
                    {path: "", element: <Home/>},
                    {
                        path: "users",
                        element: (
                            <Suspense fallback={<div>Идёт загрузка юзеров...</div>}>
                                <Users/>
                            </Suspense>
                        ),
                    },
                    {
                        path: "users/add-user",
                        element: (
                            <Suspense fallback={<div>Идёт загрузка...</div>}>
                                <AddUser/>
                            </Suspense>
                        ),
                    },
                    {
                        path: "users/:id",
                        element: (
                            <Suspense fallback={<div>Идёт загрузка...</div>}>
                                <UserCard/>
                            </Suspense>
                        ),
                    },
                    {
                        path: "equipment_glossary",
                        element: (
                            <Suspense fallback={<div>Идёт загрузка...</div>}>
                                <EquipmentGlossary/>
                            </Suspense>
                        ),
                    },
                    {
                        path: "equipment_glossary/add-item",
                        element: (
                            <Suspense fallback={<div>Идёт загрузка...</div>}>
                                <AddEquipment/>
                            </Suspense>
                        ),
                    },
                    {
                        path: "equipment_glossary/:id",
                        element: (
                            <Suspense fallback={<div>Идёт загрузка...</div>}>
                                <EquipmentCard/>
                            </Suspense>
                        ),
                    },
                    {
                        path: "documents",
                        element: (
                            <Suspense fallback={<div>Идёт загрузка...</div>}>
                                <Documents/>
                            </Suspense>
                        ),
                    },
                    {
                        path: "documents/:docNumber",
                        element: (
                            <Suspense fallback={<div>Идёт загрузка...</div>}>
                                <DocumentCard/>
                            </Suspense>
                        ),
                    },
                    {
                        path: "documents/loan/create",
                        element: (
                            <Suspense fallback={<div>Идёт загрузка...</div>}>
                                <CreateLoanDocWizard/>
                            </Suspense>
                        ),
                    },
                    {
                        path: "documents/:docNumber/book",
                        element: (
                            <Suspense fallback={<div>Идёт загрузка...</div>}>
                                <BookItemsLoanDoc/>
                            </Suspense>
                        ),
                    },
                    {
                        path: "reports",
                        element: (
                            <Suspense fallback={<div>Идёт загрузка...</div>}>
                                <Reports/>
                            </Suspense>
                        ),
                    },
                    {
                        path: "*",
                        element: (
                            <Suspense fallback={<div>Идёт загрузка...</div>}>
                                <NotFound/>
                            </Suspense>
                        ),
                    },
                ],
            },
        ],
        {
            future: {
                v7_relativeSplatPath: true, // Включаем флаг для React Router v7
                v7_fetcherPersist: true,
                v7_normalizeFormMethod: true,
                v7_partialHydration: true,
                v7_skipActionErrorRevalidation: true,
                v7_startTransition: true,
            },
        }
    );

    return <RouterProvider router={router}
                           future={{
                               v7_startTransition: true,
                           }}/>;
}

export default App;

