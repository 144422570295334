import {useEffect, useState} from "react";
import EquipmentTableGroup from "./EquipmentTableGroup";
import {getEquipmentGroupList, getEquipmentList} from "../../services/equipment/EquipmentService";

function EquipmentTable(
    {
        searchValue = '',
        dateRange = [undefined, undefined],
        activeTab,
        enableGroupSelection,
        enableEquipmentSelection,
        onGroupChange,
        onEquipmentChange,
        initialSelectedGroups = [],
        goToEquipEditPageOnClick,
    }) {
    const [arrItems, setArrItems] = useState([]);
    const [groups, setGroups] = useState([]);
    const searchValueLowerCase = searchValue.toLowerCase();

    const [selectedGroup, setSelectedGroup] = useState(initialSelectedGroups);
    const [selectedEquipment, setSelectedEquipment] = useState([]);

    const [allEquipmentSelected, setAllEquipmentSelected] = useState(false);

    const handleGroupChange = (newState) => {
        setGroups(newState);
    };

    const handleSelectAllEquipments = () => {
        const selectAll = !allEquipmentSelected; // Инвертируем текущее состояние
        setAllEquipmentSelected(selectAll);

        if (selectAll) {
            // Если выбрано всё
            setSelectedEquipment(arrItems);
            onEquipmentChange(arrItems)
        } else {
            // Снимаем выделение
            setSelectedEquipment([]);
            onEquipmentChange([])
        }
    };

    useEffect(() => {
        if (allEquipmentSelected) {
            setSelectedEquipment(arrItems);
        }
    }, [allEquipmentSelected, arrItems]);

    useEffect(() => {
        if (enableGroupSelection) {
            onGroupChange(selectedGroup)
        } else {
            onEquipmentChange(selectedEquipment)
        }
    }, [selectedGroup, selectedEquipment, onGroupChange, onEquipmentChange, enableGroupSelection]);


    useEffect(() => {
        getEquipmentList(dateRange[0], dateRange[1])
            .then((equipList) => {
                setArrItems(equipList);
            });
        //todo deps on dateRange leads to infinitely calling api when dateRange is undefined dates or is not displayed
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(dateRange)]);

    useEffect(() => {
        getEquipmentGroupList()
            .then((groupList) => {
                const updatedGroups = groupList.map((group) => ({...group, open: true}));
                setGroups(updatedGroups);
            });
    }, []);

    return (
        <>
            <div className="equipment-table">
                <div
                    className={`equipment-table-row equipment-table-head ${
                        enableEquipmentSelection ? "equipment-table-row__check" : ""
                    }`}
                >
                    {enableEquipmentSelection && (
                        <div className="check">
                            <input
                                type="checkbox"
                                className="custom-checkbox"
                                id="select-all"
                                checked={allEquipmentSelected}
                                onChange={handleSelectAllEquipments}
                            />
                        </div>
                    )}
                    <div className="equipment-table-head__item">
                        <p>Identifier</p>
                    </div>

                    <div>
                        <p>Name</p>
                    </div>
                    <div>
                        <p>Year</p>
                    </div>

                    <div className="equipment-table-head__item">
                        <p>Rating</p>
                    </div>
                </div>

                {groups
                    .filter((group) => group.type === activeTab?.name)
                    .filter((group) => {
                        if (!searchValue.trim()) {
                            return true;
                        } else {
                            //todo отфильтровать элементы по searchValue. Если эл-ты есть, группу распечатать
                            const newArr = arrItems
                                .filter((item) => group.name === item.group)
                                .filter((item) => {
                                    return (
                                        item.innerId.toLowerCase().includes(searchValueLowerCase) ||
                                        item.name.toLowerCase().includes(searchValueLowerCase)
                                    );
                                })
                                .map((item) => item);

                            return !!(newArr.length);
                        }
                    })
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((group) => {
                        return (
                            <EquipmentTableGroup
                                group={group}
                                initItems={arrItems
                                    .filter((item) => group.name === item.group)
                                    //todo move filtering to backend
                                    .filter((item) => {
                                        if (!searchValue.trim()) {
                                            return true;
                                        }
                                        return (
                                            item.innerId.toLowerCase().includes(searchValueLowerCase) ||
                                            item.name.toLowerCase().includes(searchValueLowerCase)
                                        );
                                    })
                                    .sort((a, b) => a.rating - b.rating)}
                                groupsChange={handleGroupChange}
                                // used instead of hash function to force re-rendering on arrItems changes
                                key={JSON.stringify(arrItems.filter((item) => group.name === item.group))}
                                searchValue={searchValue}
                                selectedGroups={selectedGroup}
                                setSelectedGroups={setSelectedGroup}
                                selectedEquipment={selectedEquipment}
                                setSelectedEquipment={setSelectedEquipment}
                                enableGroupSelection={enableGroupSelection}
                                enableEquipmentSelection={enableEquipmentSelection}
                                goToEquipEditPageOnClick={goToEquipEditPageOnClick}
                            />
                        );
                    })}
            </div>
            {groups.length === 0 && (
                <div className="table_empty">
                    <h4 className="title">Let's get started! </h4>
                    <p className="text">Add your first item</p>
                </div>
            )}
        </>
    );
}

export default EquipmentTable;

