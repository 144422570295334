import { useEffect, useRef, useState } from "react";
import Select, { components, OnChangeValue, StylesConfig } from "react-select";
import Check from "../assets/img/Icons/Check.svg";
import ChevronSelect from "../assets/img/Icons/ChevronSelect.svg";

const CustomChevron: React.FC<{ isOpen: boolean }> = ({ isOpen }) => (
    <img
        src={ChevronSelect}
        alt="Chevron"
        style={{
            width: "16px",
            height: "16px",
            transition: "transform 0.2s ease", // Добавляем плавный переход
            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)", // Вращаем chevron
        }}
    />
);

export interface Option {
    value: string;
    label: string;
}

interface CustomSelectProps {
    className?: string;
    placeholder?: string;
    options: Option[];
    value: Option[];
    onChange: (newValue: Option[]) => void;
}

const CustomValueContainer = ({ children, ...props }: any) => {
    const hasValue = props.hasValue ?? false;
    return (
        <components.ValueContainer {...props}>
            <components.Placeholder {...props}>{props.selectProps.placeholder}</components.Placeholder>
            {!hasValue && children}
        </components.ValueContainer>
    );
};

const CustomSelect: React.FC<CustomSelectProps> = ({ className, placeholder, options, value, onChange }) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const selectRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const customStyles: StylesConfig<Option, true> = {
        control: (provided, state) => ({
            ...provided,
            minHeight: "40px",
            backgroundColor: isMenuOpen || state.hasValue ? "#F9F9F9" : "#FFF",
            fontFamily: "'Poppins', sans-serif",
            fontSize: "14px",
            fontWeight: "500",
            color: isMenuOpen ? "#0A0A0A" : "#424242",
            borderColor: "#E0E0E0",
            borderRadius: "12px",
            cursor: "pointer",
            boxShadow: "none",

            "&:hover": {
                color: "#0A0A0A",
            },
            "&:active": {
                color: "#424242",
            },
        }),

        valueContainer: (provided: any) => ({
            ...provided,
            padding: "0px",
        }),

        menu: (base) => ({
            ...base,
            backgroundColor: "#ffffff",
            borderRadius: "12px",
            color: "#424242",
            margin: "12px 0 0 0",
            overflow: "hidden",
        }),

        menuList: (base) => ({
            ...base,
            padding: "0",
        }),

        option: (provided) => ({
            ...provided,
            display: "flex",
            cursor: "pointer",
            color: "#424242",
            fontWeight: "400",
            lineHeight: "20px",
            backgroundColor: "#FFF",
            padding: "6px 16px",
            width: "100%",

            "&:hover": {
                fontWeight: "500",
            },
            "&:active": {
                fontWeight: "500",
                color: "#423293",
                backgroundColor: "#FFF",
            },
        }),

        placeholder: (provided) => ({
            ...provided,
        }),

        dropdownIndicator: (provided) => ({
            ...provided,
            color: "#757575",
            transition: "color 0.2s ease",
            ":hover": {
                color: "#757575",
            },
            ":active": {
                color: "#424242",
            },
        }),
        indicatorSeparator: () => ({
            display: "none",
        }),

        clearIndicator: () => ({
            display: "none",
        }),
    };

    const CustomOption = (props: any) => (
        <components.Option {...props}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                <div>{props.data.label}</div>
                {props.isSelected && (
                    <div>
                        <img src={Check} alt="Check" style={{ width: "12px", height: "12px" }} />
                    </div>
                )}
            </div>
        </components.Option>
    );

    const CustomMenuList = (props: any) => (
        <components.MenuList {...props}>
            {props.children}
            <div
                onClick={() => props.selectProps.onChange([])}
                style={{
                    cursor: props.selectProps.value?.length > 0 ? "pointer" : "default",
                    color: props.selectProps.value?.length > 0 ? "#5E47D2" : "#9C9C9C",
                    padding: "6px 16px",
                }}
            >
                Clear All
            </div>
        </components.MenuList>
    );

    const MultiValue: React.FC<any> = () => <div style={{ display: "none" }} />;

    return (
        <div ref={selectRef} className="custom-select__wrapper">
            <Select
                isMulti
                className={className}
                classNamePrefix="custom-select"
                styles={customStyles}
                options={options}
                placeholder={placeholder}
                value={value}
                onChange={(newValue: OnChangeValue<Option, true>) => {
                    onChange(newValue as Option[]);
                }}
                menuIsOpen={isMenuOpen}
                onMenuOpen={() => setIsMenuOpen(true)}
                onMenuClose={() => setIsMenuOpen(false)}
                components={{
                    ValueContainer: CustomValueContainer,
                    MultiValue,
                    DropdownIndicator: (props) => <CustomChevron isOpen={isMenuOpen} {...props} />,
                    Option: CustomOption,
                    MenuList: CustomMenuList,
                }}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                isClearable={true}
            />
        </div>
    );
};

export default CustomSelect;

