import React from "react";
import {ScrollMenu, VisibilityContext} from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";

import {LeftArrow, RightArrow} from "./ScrollArrow";

import usePreventBodyScroll from "./usePreventBodyScroll";

import "./hideScrollbar.css";

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

interface IProps {
    tabs: ITab[];
    activeTabId: ITab;
    setActiveTabId: (tab: ITab) => void;
}

interface ITab {
    name: string;
    id: string;
}

function TabsMenu({tabs, activeTabId, setActiveTabId}: IProps) {
    const {disableScroll, enableScroll} = usePreventBodyScroll();

    const openTab = (tab: ITab) => {
        setActiveTabId(tab);
    };

    return (
        <div onMouseEnter={disableScroll} onMouseLeave={enableScroll} className="tabs__list">
            <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} onWheel={onWheel}>
                {tabs.map((tab) => (
                    <div
                        className={`tabs__item ${tab.id === activeTabId.id ? "tabs__item_active" : ""}`}
                        onClick={() => openTab({name: tab.name, id: tab.id})}
                        key={tab.id}
                    >
                        <div className={`tablinks `}>{tab.name}</div>
                    </div>
                ))}
            </ScrollMenu>
        </div>
    );
}

export default TabsMenu;

function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
        ev.stopPropagation();
        return;
    }

    if (ev.deltaY < 0) {
        apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
        apiObj.scrollPrev();
    }
}

